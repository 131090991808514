import React from "react";
import { Route, Routes } from "react-router-dom";
import Respon from "./kuisioner";

const PathKuisioner = () => {
  return (
    <Routes>
      <Route
        path="/kuisioner"
        element={
          <>
            <Respon />
          </>
        }
      />
    </Routes>
  );
};

export default PathKuisioner;
