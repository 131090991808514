import React from "react";
import { Link } from "react-router-dom";

const Success = () => {
  return (
    <main className="main-page">
      {/* ======= Speaker Details Sectionn ======= */}
      <section>
        <div className="container">
          <div className="section-header text-center">
            {" "}
            {/* Tambahkan kelas text-center di sini */}
            <h2>Selamat Pendaftaran Berhasil</h2>
            {/* <p>Praesentium ut qui possimus sapiente nulla.</p> */}
          </div>
          <div className="row">
            <div className="col-md-12">
              <img
                src="assets/img/success.png"
                alt="Sukses"
                className="img-fluid mx-auto d-block"
              />
            </div>
            <div className="col-md-12">
              <div className="details text-center">
                <h2>
                  Silahkan Lakukan Pembayaran dan Konfirmasi ke Contact Person
                </h2>
                <p>Informasi Pembayaran :</p>
                <p>BRI 814101011687532 a/n Fita Febriani</p>
                <p>DANA 085743947388 a/n Igor Adrian</p>
                <Link to="http://wa.me/+6281366756842" className="btn btn-danger">
                  Konfirmasi Disini
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Success;
