import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet'; // Import Helmet
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Helmet>
      <script src="/assets/vendor/aos/aos.js"></script>
      <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
      <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
      <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
      <script src="/assets/vendor/php-email-form/validate.js"></script>
      <script src="/assets/js/main.js"></script>
    </Helmet>
    <App />
  </React.StrictMode>
);
