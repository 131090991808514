import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./Header";
import Home from "./Home";
import Footer from "./Footer";
import Section from "./Section";

const All = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Header />
            <Section />
            <Home />
            <Footer />
          </>
        }
      />
    </Routes>
  );
};

export default All;
